import { SVGProps } from 'react';

export const SvgSunGlow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M8.98744 12C-3.24639 9.89621 -2.81988 7.89021 9.22499 10.8266C-1.2758 4.24141 -0.158239 2.59861 9.87015 9.86827C2.60058 -0.15894 4.24183 -1.27649 10.8284 9.22289C7.89147 -2.81871 9.89714 -3.24617 12 8.98541C14.1056 -3.24617 16.1112 -2.81871 13.1716 9.22289C19.7582 -1.27649 21.3994 -0.15894 14.1299 9.86827C24.1582 2.59861 25.2758 4.24141 14.775 10.8266C26.8172 7.89021 27.2437 9.89342 15.0126 12C27.2464 14.1038 26.8199 16.1098 14.775 13.1734C25.2758 19.7586 24.1582 21.4014 14.1299 14.1317C21.3994 24.1589 19.7582 25.2765 13.1716 14.7771C16.1085 26.8187 14.1029 27.2462 12 15.0146C9.89444 27.2462 7.88877 26.8187 10.8284 14.7771C4.24183 25.2765 2.60058 24.1589 9.87015 14.1317C-0.158239 21.4014 -1.2758 19.7586 9.22499 13.1734C-2.81718 16.1098 -3.24369 14.1066 8.98744 12Z" />
  </svg>
);
